<template>
  <div class="sale">
    <div class="df-row">
      <div class="df-col">
        <el-input
          style="width: 300px;"
          v-model="submitData.word"
          placeholder="请输入会员名称或电话"
          clearable
          size="mini"
          @keyup.enter.native="onSearch"
        ></el-input>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddSale"
          >新增</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableList"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="MemberName" label="会员名称"> </el-table-column>
      <el-table-column prop="MemberPhone" label="会员电话"> </el-table-column>
      <el-table-column
        prop="MemberStoresName"
        label="会员所属门店"
      ></el-table-column>
      <el-table-column prop="EmplName" label="员工名称"></el-table-column>
      <el-table-column prop="EmplNo" label="员工编号"></el-table-column>
      <el-table-column
        prop="EmplStoresName"
        label="员工所属门店"
      ></el-table-column>
      <el-table-column prop="" label="建立时间">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.CreateTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            :disabled="delDisabled"
            @click="deleteSalesReferee(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <AddSaleDialog
      ref="AddSaleDialog"
      @success="getSalesRefereeList"
    ></AddSaleDialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

import AddSaleDialog from "./components/add-sale-dialog.vue";

export default {
  components: { AddSaleDialog },

  data() {
    return {
      totalRecord: 0,
      tableMaxHeight: "",

      submitData: {
        word: "",
        page_index: 1,
        page_size: 20,
      },
      tableList: [],
      loading: false,
      delDisabled: false
    };
  },
  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 查询销售关系
    async getSalesRefereeList() {
      this.loading = true;
      try {
        let { data } = await Shop.getSalesRefereeList(this.submitData);
        this.tableList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除销售关系
    deleteSalesReferee(event) {
      let that = this;
      this.$confirm("确定删除该销售关系吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          that.delDisabled = true;
          try {
            let { errcode } = await Shop.deleteSalesReferee({
              member_guid: event.MemberGuid,
              empl_guid: event.EmplGuid,
            });
            if (errcode == 0) {
              that.$message.success("删除成功");
              that.getSalesRefereeList();
            }
          } catch (err) {
            console.log(err);
          } finally {
            that.delDisabled = false;
          }
        });
    },

    onSearch() {
      this.submitData.page_index = 1;
      this.getSalesRefereeList();
    },

    onAddSale() {
      this.$refs.AddSaleDialog.onShowDialog();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getSalesRefereeList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getSalesRefereeList();
    },
  },
};
</script>

<style lang="less" scoped>
.sale {
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>
