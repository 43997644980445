<template>
  <div>
    <el-dialog
      title="添加销售关系"
      :visible.sync="showDialog"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto" size="small">
          <el-form-item label="选择会员">
            <div class="df-row">
              <div class="df-col">
                <DfMember
                  ref="DFMember"
                  v-model="word"
                  size="small"
                  placeholder=""
                  @change="onSelectMember"
                ></DfMember>
              </div>
            </div>
            <div v-if="submitData.member_guid" class="df-row">
              <div class="df-col">会员名称：{{ memberInfo.MemberName }}</div>
              <div class="df-col">会员电话：{{ memberInfo.MemberPhone }}</div>
              <div class="df-col">所属门店：{{ memberInfo.StoresName }}</div>
            </div>
          </el-form-item>
          <el-form-item label="选择员工">
            <el-select
              v-model="submitData.empl_guid"
              clearable
              filterable
              @change=""
            >
              <el-option
                v-for="item in emplList"
                :key="item.EmplGuid"
                :label="item.EmplNo + '_' + item.EmplName"
                :value="item.EmplGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="addSalesReferee">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Employee from "@/api/employee.js";
import Shop from "@/api/shop.js";

export default {
  data() {
    return {
      word: "",
      memberInfo: {
        MemberGuid: "",
        MemberName: "",
        MemberPhone: "",
        StoresName: "",
      },
      submitData: {
        empl_guid: "",
        member_guid: "",
      },
      emplList: [],
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 初始化员工列表
    async getCommonSelector() {
      try {
        let { data } = await Employee.getCommonSelector();
        this.emplList = data.emplList;
      } catch (err) {
        console.log(err);
      }
    },

    // 添加销售关系
    async addSalesReferee(event) {
      let { submitData } = this;
      if (!submitData.member_guid) {
        return this.$message.error("请选择会员");
      } else if (!submitData.empl_guid) {
        return this.$message.error("请选择员工");
      }

      try {
        let { errcode } = await Shop.addSalesReferee(this.submitData);
        if (errcode == 0) {
          this.$message.success("添加成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 选中会员
    onSelectMember(event) {
      //   console.log(event);
      this.memberInfo = event;
      this.submitData.member_guid = event.MemberGuid;
    },

    // 重置数据
    resetPageData() {
      this.submitData = {
        empl_guid: "",
        member_guid: "",
      };
      this.word = "";
    },

    // 打开对话框
    onShowDialog() {
      this.getCommonSelector();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetPageData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .el-input {
    width: 200px;
  }
}
</style>
